const palette = {
  mode: 'dark',
  primary: {
    main: '#9c27b0',
    light: '#ba68c8',
    dark: '#7b1fa2',
  },
  secondary: {
    main: '#673ab7',
    light: '#9575cd',
    dark: '#512da8',
  },
  background: {
    default: '#121212',
    paper: '#1e1e1e',
  },
  text: {
    primary: '#ffffff',
    secondary: '#b0bec5',
  },
};

export default palette;